<template>
  <Layout class="InvitationCode">
    <div class="overview">
      <div class="items">
        <div class="num">
          {{ statisticsInfo.cumulativePayingUsers || '-' }}
        </div>
        <div class="name">
          累计付费用户
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ (statisticsInfo.cumulativePaymentAmount / DIVISOR.TRUE_AMOUNT) || '-' }}
        </div>
        <div class="name">
          累计付费金额($)
        </div>
      </div>
    </div>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="formData.activityName"
            clearable
            placeholder="活动名称"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.globalStatus"
            placeholder="状态"
            clearable
          >
            <el-option
              v-for="(item, index) in promotionStateList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <el-table
      ref="table"
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
    >
      <el-table-column
        prop="activityDetail.activityName"
        label="兑换活动"
      />
      <el-table-column
        prop="activityDetail.cumulativePayingUsers"
        label="付费用户数"
        width="120px"
      />
      <el-table-column
        prop="activityDetail.cumulativePaymentAmount"
        label="累计付费金额($)"
        width="140px"
      >
        <template #default="{row}">
          {{ row.activityDetail.cumulativePaymentAmount / DIVISOR.TRUE_AMOUNT }}
        </template>
      </el-table-column>
      <el-table-column
        prop="activityDetail.globalStatus"
        label="活动状态"
      >
        <template #default="{row}">
          {{ (promotionStateMap[row.activityDetail.globalStatus] || {}).name || '未定义' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="activityDetail.inviteCode"
        label="邀请码"
      />
      <el-table-column
        label="操作"
        width="220px"
      >
        <template #default="{row}">
          <BTextButton
            title="数据表现"
            icon="fa fa-line-chart"
            @click="handleViewItem(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import {
  promotionStateList,
  promotionStateMap,
  triggerModeList,
  triggerModeMap,
  rewardTypetMap,
  osPlatformMap,
  appMap
} from '@/utils/selectOptions.js'
import {
  getInviteActivityListFromExternalKol,
  getInviteActivityStatisticsFromExternalKol
  // couponCodeGetGoods
} from '@/api/CouponsAndRedeemCode.js'

import {
  DIVISOR,
  // APPID,
  // GOODS_TYPE,
  // CHANNEL,
  // EXPERIMENT_GROUP,
  triggerModeValue
} from '@/enum'

const codeTypeMap = {
  1: '通用码',
  2: '1人1码'
}
export default {
  name: 'InvitationCodeNoCache',
  data () {
    return {
      promotionStateList,
      promotionStateMap,
      triggerModeValue,
      promotionChannelList: [],
      promotionChannelMap: {},
      allRewardCountList: [],
      allRewardCountMap: {},
      triggerModeList,
      triggerModeMap,
      rewardTypetMap,
      osPlatformMap,
      appMap,
      codeTypeMap,
      formData: {
        dateRange: [],
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      statisticsInfo: {},
      DIVISOR,
      rewardCountList: [],
      rewardCountMap: {}
    }
  },
  created () {
    this.queryDataList()
    this.queryInviteActivityStatistics()
  },
  methods: {
    dataFomat (date) {
      if (date === '0000-00-00 00:00:00' || !date) { return '-' }
      return date
    },
    queryInviteActivityStatistics () {
      if (this.formData.dateRange) {
        const [startDate, endDate] = this.formData.dateRange
        this.formData.startDate = startDate
        this.formData.endDate = endDate
      } else {
        delete this.formData.startDate
        delete this.formData.endDate
      }
      getInviteActivityStatisticsFromExternalKol({ ...this.formData })
        .then(res => {
          if (res.code === 200) {
            this.statisticsInfo = res.data
          }
        })
    },
    queryDataList () {
      this.listInfo.loading = true
      if (this.formData.dateRange) {
        const [startTime, endTime] = this.formData.dateRange
        this.formData.startDate = startTime
        this.formData.endDate = endTime
      } else {
        delete this.formData.startDate
        delete this.formData.endDate
      }
      getInviteActivityListFromExternalKol({ ...this.formData })
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    handleViewItem (row) {
      console.log({
        id: row.activityDetail.id,
        name: row.activityDetail.activityName,
        total: row.activityDetail.cumulativeRegisteredUsers,
        start: row.activityDetail.startUsedTime,
        end: row.activityDetail.endUsedTime
      })
      this.$router.push({
        name: 'ExternalKolList',
        query: {
          id: row.activityDetail.id,
          name: row.activityDetail.activityName,
          total: row.activityDetail.cumulativeRegisteredUsers,
          start: row.activityDetail.startUsedTime,
          end: row.activityDetail.endUsedTime
        }
      })
    }
  }
}
</script>

<style lang="less">
  .InvitationCode{
    .el-table__expanded-cell{
      background-color: #FAFCFD;
      padding: 30px 0;
      .table-expand{
        label {
          width: 100px;
          color: #99a9bf;
        }
        .el-form-item{
          margin-bottom: 0;
        }
      }
    }
  }
</style>

<style scoped lang="less">

  .InvitationCode {
    .overview {
      display: flex;
      padding: 20px 0;
      min-width: 880px;
      .items {
        width: 210px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
        }
        .name {
          font-size: 12px;
        }
      }
    }
    .rewardRows {
      margin-bottom: 10px;
    }
  }
</style>
